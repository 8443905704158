<div fxLayoutAlign="space-between start" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px" fxFlex>
    <div class="filter-container" fxLayout="row wrap" fxLayoutGap="8px" fxFlex>
      <ng-container *ngIf="isAdvancedSearchFiltersSelected">
        <div *ngFor="let searchFilter of advancedSearchFilters" class="search-filter-container" [fxHide]="!searchFilter.isSelected">
          <div
            type="button"
            [id]="searchFilter.propertyName"
            class="vdw-button vdw-secondary-button"
            [ngStyle]="{padding: '0 16px'}"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            (click)="openFilterDialog(searchFilter.propertyName)"
          >
            <span>{{ searchFilter.title }}</span>
            <span *ngIf="searchFilter.summary" class="font-weight-bold">{{ searchFilter.summary | translate }}</span>
            <mat-icon *ngIf="showArrowDownIcon(searchFilter)" svgIcon="chevron-down" class="chevron"></mat-icon>
          </div>
        </div>
        <button *ngIf="showAddButton" mat-stroked-button mat-icon-button (click)="addData()">
          <mat-icon svgIcon="plus-2.5px"></mat-icon>
        </button>
        <!-- This part will be reinstate with the commencing work on user story 7721 -->
        <!-- <button *ngIf="showDeleteButton" mat-stroked-button mat-icon-button (click)="deleteData()">
          <mat-icon svgIcon="delete"></mat-icon>
        </button> -->
      </ng-container>
      <div>
        <button *ngIf="showGeneralFilter && advancedSearchOptionFilters.length > 1" #filterOptions class="vdw-button vdw-secondary-button filter-button" fxLayout="row" (click)="openOptionshDialog()">
          <mat-icon svgIcon="filter"></mat-icon>
          <span>{{ 'GENERAL.ADVANCED_SEARCH.MORE_FILTERS' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="clear-filters-container" fxLayoutAlign="center center">
      <a class="url" (click)="clearAllFilters()" [ngClass]="{disabled: !isFilterSelected()}" fxLayout="row"
        ><mat-icon svgIcon="redo"></mat-icon><span>{{ 'GENERAL.ADVANCED_SEARCH.RESET' | translate }}</span></a
      >
    </div>
  </div>
</div>

<div *ngIf="advancedSearchOptionFilters.length > 0" class="option-filter-margin" fxLayoutAlign="space-between start" fxLayoutGap="8px">
  <div class="filter-container" fxLayout="row wrap" fxLayoutGap="8px" fxFlex>
    <div *ngFor="let filterOption of advancedSearchOptionFilters" class="search-filter-container">
      <ng-container *ngIf="filterOption.filterType === advancedSearchOptionFilterTypeEnum.RADIO_BUTTON">
        <div fxLayout="row" fxLayoutGap="8px" fxFlex style="color: #000000">
          <mat-radio-group fxLayoutGap="8px">
            <mat-label>{{ filterOption.fieldName }}&nbsp; : &nbsp;</mat-label>
            <ng-container *ngFor="let option of filterOption.propertyOptions">
              <mat-radio-button [value]="option" [checked]="filterOption.selectedValue === option" (change)="onSelectionChange($event.value, filterOption.propertyName)">
                {{ option.text }}
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>
      </ng-container>
    </div>
  </div>
</div>
