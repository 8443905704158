import {RouteBranch} from '../route-branch';
import {ComponentSettingRoute} from './component-setting.routes';
import {ConfigurationSettingRoute} from './configuration-setting.routes';
import {JacquardRoute} from './jacquard-route';
import {MachineConfigurationRoute} from './machine-configuration.routes';
import {MachineTypeRoute} from './machine-type.routes';
import {OrderDeliveryRoute} from './order-delivery.route';

export class OpportunityRoute extends RouteBranch {
  private _jacquard: JacquardRoute;
  private _machineType: MachineTypeRoute;
  private _componentSetting: ComponentSettingRoute;
  private _configurationSetting: ConfigurationSettingRoute;
  private _orderDelivery: OrderDeliveryRoute;
  private _machineConfiguration: MachineConfigurationRoute;

  public get jacquard(): JacquardRoute {
    return this._jacquard;
  }

  public set jacquard(value: JacquardRoute) {
    this.replace(this._jacquard, value);
    this._jacquard = value;
  }

  public get machineType(): MachineTypeRoute {
    return this._machineType;
  }

  public set machineType(value: MachineTypeRoute) {
    this.replace(this._machineType, value);
    this._machineType = value;
  }

  public get componentSetting(): ComponentSettingRoute {
    return this._componentSetting;
  }

  public set componentSetting(value: ComponentSettingRoute) {
    this.replace(this._componentSetting, value);
    this._componentSetting = value;
  }

  public get configurationSetting(): ConfigurationSettingRoute {
    return this._configurationSetting;
  }

  public set configurationSetting(value: ConfigurationSettingRoute) {
    this.replace(this._configurationSetting, value);
    this._configurationSetting = value;
  }

  public get orderDelivery(): OrderDeliveryRoute {
    return this._orderDelivery;
  }

  public set orderDelivery(value: OrderDeliveryRoute) {
    this.replace(this._orderDelivery, value);
    this._orderDelivery = value;
  }

  public get machineConfiguration(): MachineConfigurationRoute {
    return this._machineConfiguration;
  }

  public set machineConfiguration(value: MachineConfigurationRoute) {
    this.replace(this._machineConfiguration, value);
    this._machineConfiguration = value;
  }
}
