export enum OpportunityApprovalStatus {
  DRAFT = 1,
  PENDING_APPROVEL,
  APPROVED,
  RETURNED,
  UPDATE
}

export const OPPORTUNITY_STATUS_UPDATABLE_STATUSES = [OpportunityApprovalStatus.DRAFT, OpportunityApprovalStatus.APPROVED, OpportunityApprovalStatus.RETURNED, OpportunityApprovalStatus.UPDATE];
export const OPPORTUNITY_APPROVAL_SHOW_REMARK_STATUSES = [OpportunityApprovalStatus.RETURNED];
