import {HttpParams} from '@angular/common/http';
import {InjectionToken} from '@angular/core';
import {Module} from '@domain/organization/role/module.enum';
import {FileResponse} from '@infrastructure/http/file.response';
import {UniqueIdentifierObjectRepository} from '@infrastructure/http/unique-identifier-object-repository';
import {Observable} from 'rxjs/internal/Observable';
import {BindCompanyRelationRequest} from './bind-company-relation.request';
import {CreateCustomerRequest} from './create-customer.request';
import {CustomerFilterOptionResponse} from './customer-filter-option.response';
import {CustomerFiltersRequest} from './customer-filters.request';
import {CustomerPaymentRequest} from './customer-payment.request';
import {CustomerPaymentResponse} from './customer-payment.response';
import {CustomerStatusRequest} from './customer-status.request';
import {CustomerUserOverviewResponse} from './customer-user-overview.response';
import {CustomerUserStatusRequest} from './customer-user-status.request';
import {CustomerUserRequest} from './customer-user.request';
import {CustomerUserResponse} from './customer-user.response';
import {CustomerResponse} from './customer.response';
import {SharedCustomerDataResponse} from './shared-customer-data.response';
import {SubsidiaryCompanyDetailsResponse} from './subsidiary-company-details.response';
import {UnbindCompanyRelationRequest} from './unbind-company-relation.request';
import {UpdateCustomerRequest} from './update-customer.request';

export const CUSTOMER = new InjectionToken<ICustomerService>('ICustomer');

export interface ICustomerService extends UniqueIdentifierObjectRepository {
  addUser(customerId: number, request: CustomerUserRequest): Observable<number>;
  editUser(customerId: number, userId: number, request: CustomerUserRequest): Observable<number>;
  setMainUser(customerId: number, userId: number): Observable<number>;
  getUsersByCustomerId(customerId: number): Observable<CustomerUserOverviewResponse[]>;
  getUserById(customerId: number, id: number): Observable<CustomerUserResponse>;
  updateStatus(customerId: number, customerVerificationRequest: CustomerStatusRequest): Observable<number>;
  updateCustomerUserStatus(customerId: number, userId: number, customerUserStatusRequest: CustomerUserStatusRequest): Observable<number>;

  getPaymentByCustomerId(customerId: number): Observable<CustomerPaymentResponse>;
  editPayment(customerId: number, request: CustomerPaymentRequest): Observable<number>;

  getAll(filter?: CustomerFiltersRequest, isRequiredOemPermissionValidation?: boolean, module?: Module): Observable<CustomerResponse[]>;
  getCustomerById(id: number, isFilterByPermission?: boolean): Observable<CustomerResponse>;
  editCustomer(request: UpdateCustomerRequest): Observable<number>;

  getBusinessLicenseFile(request: string): Observable<FileResponse>;
  uploadBusinessLicenseFile(request: File): Observable<FileResponse>;

  bindCompanyRelation(request: BindCompanyRelationRequest): Observable<number>;
  unbindCompanyRelation(request: UnbindCompanyRelationRequest): Observable<number>;

  getFilterOptions(): Observable<CustomerFilterOptionResponse>;
  getOptions(
    selectedId: number,
    keywordSearch: string,
    startRow: number,
    endRow: number,
    column: string,
    sort: string,
    ignoredIds: number[],
    isFilteredByOuAreas: boolean,
    selectedRelationType?: number,
    isRequiredOemPermissionValidation?: boolean,
    module?: Module,
    internalCompanyId?: number,
    isFilterByPermission?: boolean
  ): Observable<any>;
  getCustomerUsers(isCustomerActive: boolean): Observable<CustomerUserOverviewResponse[]>;

  getSubsidiaryCompanyDetails(ids: number[]): Observable<SubsidiaryCompanyDetailsResponse[]>;
  getPaginated(filtersQuery: HttpParams): Observable<any>;
  getSharedCustomerById(id: number): Observable<SharedCustomerDataResponse>;
  createCustomer(request: CreateCustomerRequest): Observable<number>;
  getSharedCustomerDropDown(selectedId: number, keywordSearch: string, startRow: number, endRow: number, column: string, sort: string): Observable<any>;
}
