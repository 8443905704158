import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@application/translation/translate.service';
import {CustomerStatus} from '@domain/customer/customer-status.enum';
import {RegistrationStatus, REGISTRATION_VERIFIED_STATUSES} from '@domain/customer/registration-status.enum';
import {ErpCustomerSyncStatus} from '@domain/erp-customer/erp-customer-sync-status.enum';
import {PriceStatus} from '@domain/product-catalogue/price-status.enum';
import {PriceVerificationStatus, PRICE_VERIFICATION_VERIFIED_STATUSES} from '@domain/product-catalogue/price-verification-status.enum';
import {CustomerVerificationStatus} from '@domain/sales/customer-verification-status.enum';
import {AxLogStatus} from '@domain/sales/order/ax-log-status';
import {DeliveryFormStatus} from '@domain/sales/order/delivery-form-status.enum';
import {InvoiceStatus} from '@domain/sales/order/invoice-status.enum';
import {OrderStatus} from '@domain/sales/order/order-status.enum';
import {PaymentStatus} from '@domain/sales/payment/payment-status.enum';
import {SalesStatus} from '@domain/sales/sales-status.enum';
import {SalesVerificationStatus, SALES_VERIFICATION_VERIFIED_STATUSES} from '@domain/sales/sales-verification-status.enum';
import {OpportunityApprovalStatus} from '@domain/salesforce/opportunity/approval-status.enum';
import {OpportunityStatus} from '@domain/salesforce/opportunity/status.enum';
import {SalesforceSyncStatus} from '@domain/salesforce/salesforce-sync-status.enum';
import {StatusType} from '@domain/status-type.enum';
import {Status} from '@domain/status.enum';
import {isNil} from 'lodash-es';

@Pipe({
  name: 'statusTitle'
})
export class StatusTitlePipe implements PipeTransform {
  private readonly translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public transform(status: any, statusType: StatusType): string {
    let statusTitle: string;

    if (!isNil(status) && !isNil(statusType)) {
      let statusKey = '';
      let isVerified = false;

      switch (statusType) {
        case StatusType.GENERAL_STATUS:
          statusKey = `GENERAL.STATUS.${Status[status]}`;
          break;

        case StatusType.REGISTRATION_STATUS:
          statusKey = `CRM.REGISTRATION.STATUS.${RegistrationStatus[status]}`;
          isVerified = REGISTRATION_VERIFIED_STATUSES.includes(status);
          break;

        case StatusType.CUSTOMER_STATUS:
          statusKey = `GENERAL.STATUS.${CustomerStatus[status]}`;
          break;

        case StatusType.PRICE_VERIFICATION_STATUS:
          statusKey = `PRODUCT_CATALOGUE.PRICING.VERIFICATION_STATUS.${PriceVerificationStatus[status]}`;
          isVerified = PRICE_VERIFICATION_VERIFIED_STATUSES.includes(status);
          break;

        case StatusType.PRICE_STATUS:
          statusKey = `PRODUCT_CATALOGUE.PRICING.PRICE_STATUS.${PriceStatus[status]}`;
          break;

        case StatusType.SALES_STATUS:
          statusKey = `SALES.STATUS.${SalesStatus[status]}`;
          break;

        case StatusType.SALES_VERIFICATION_STATUS:
          statusKey = `SALES.VERIFICATION_STATUS.${SalesVerificationStatus[status]}`;
          isVerified = SALES_VERIFICATION_VERIFIED_STATUSES.includes(status);
          break;

        case StatusType.CUSTOMER_VERIFICATION_STATUS:
          statusKey = `SALES.CUSTOMER_VERIFICATION_STATUS.${CustomerVerificationStatus[status]}`;
          break;

        case StatusType.ORDER_STATUS:
          statusKey = `SALES.ORDER.ORDER_STATUS.${OrderStatus[status]}`;
          break;

        case StatusType.INVOICE_STATUS:
          statusKey = `SALES.ORDER.INVOICE_STATUS.${InvoiceStatus[status]}`;
          break;

        case StatusType.PAYMENT_STATUS:
          statusKey = `SALES.PAYMENT.PAYMENT_STATUS.${PaymentStatus[status]}`;
          break;

        case StatusType.AX_LOG_STATUS:
          statusKey = `SALES.ORDER.AX_LOG_STATUS.${AxLogStatus[status]}`;
          break;

        case StatusType.ORDER_DELIVERY_FORM_STATUS:
          statusKey = `SALES.ORDER_DELIVERY.DELIVERY_FORM.STATUS.${DeliveryFormStatus[status]}`;
          break;

        case StatusType.SALESFORCE_SYNC_STATUS:
          statusKey = `SALESFORCE.SYNC_STATUS.${SalesforceSyncStatus[status]}`;
          break;

        case StatusType.ERP_CUSTOMER_SYNC_STATUS:
          statusKey = `CRM.ERP_CUSTOMER.SYNC_STATUS.${ErpCustomerSyncStatus[status]}`;
          break;

        case StatusType.PHONE_USE_STATUS:
          statusKey = `USER.PHONE_LOG.STATUS.${Status[status]}`;
          break;

        case StatusType.OPPORTUNITY_APPROVAL_STATUS:
          statusKey = `OPPORTUNITY.JACQUARD.FORM.MACHINE.APPROVAL_STATUS.${OpportunityApprovalStatus[status]}`;
          break;

        case StatusType.OPPORTUNITY_STATUS:
          statusKey = `OPPORTUNITY.STATUS.${OpportunityStatus[status]}`;
          break;
      }

      statusTitle = this.translate.instant(statusKey);

      if (isVerified) {
        statusTitle = `${this.translate.instant('GENERAL.VERIFIED')} - ${statusTitle}`;
      }
    }

    return statusTitle;
  }
}
