export enum OpportunityApprovalStatus {
  DRAFT = 1,
  PENDING_APPROVAL,
  APPROVED,
  RETURNED,
  RETURNED_RESUBMIT
}

export const OPPORTUNITY_STATUS_UPDATABLE_STATUSES = [
  OpportunityApprovalStatus.DRAFT,
  OpportunityApprovalStatus.APPROVED,
  OpportunityApprovalStatus.RETURNED,
  OpportunityApprovalStatus.RETURNED_RESUBMIT
];
export const OPPORTUNITY_APPROVAL_SHOW_REMARK_STATUSES = [OpportunityApprovalStatus.RETURNED];
