import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {NavigationStart, Router} from '@angular/router';
import {RouteUtils} from '@application/routing/route-utils';
import {ICONS} from '@domain/icons.generated';
import {EnhancementControlService} from '@shared/services/enhancement-control.service';
import {RoutingState} from '@shared/services/routing-state.service';
import {each} from 'lodash-es';
import {filter} from 'rxjs/operators';
import {BaseComponent} from './presentation/base-component';
import {Icon, IconSet} from './presentation/pages/components/icons/icons.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit {
  private readonly iconRegistry: MatIconRegistry;
  private readonly sanitizer: DomSanitizer;
  private readonly router: Router;
  private readonly enhancementControlService: EnhancementControlService;

  public constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, routingState: RoutingState, router: Router, enhancementControlService: EnhancementControlService) {
    super();
    routingState.loadRouting();
    this.iconRegistry = iconRegistry;
    this.sanitizer = sanitizer;
    this.enhancementControlService = enhancementControlService;
    this.router = router;
  }

  public ngOnInit(): void {
    this.setUpIcons();

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      switch (event.url) {
        case RouteUtils.paths.sales.contract.absolutePath:
        case RouteUtils.paths.salesforce.absolutePath:
        case RouteUtils.paths.erp.absolutePath:
        case RouteUtils.paths.sales.order.absolutePath:
        case RouteUtils.paths.sales.quotation.absolutePath:
        case RouteUtils.paths.productCatalogue.pricing.absolutePath:
        case RouteUtils.paths.sales.payment.absolutePath:
        case RouteUtils.paths.sales.payment.absolutePath:
        case RouteUtils.paths.customerRelationship.registration.absolutePath:
        case RouteUtils.paths.customerRelationship.customer.absolutePath:
        case RouteUtils.paths.productCatalogue.product.absolutePath:
        case RouteUtils.paths.productCatalogue.sparePart.absolutePath:
        case RouteUtils.paths.organization.user.absolutePath:
        case RouteUtils.paths.orderDelivery.absolutePath:
        case RouteUtils.paths.customerRelationship.customerPriceForm.absolutePath:
        case RouteUtils.paths.opportunity.jacquard.absolutePath:
        case RouteUtils.paths.customerRelationship.prospectCustomer.absolutePath:
          this.enhancementControlService.isPaginationEnabled = true;
          break;
        default:
          this.enhancementControlService.isPaginationEnabled = false;
      }
    });
  }

  private setUpIcons(): void {
    const icons: IconSet[] = ICONS;

    each(icons, (iconSet: IconSet) => {
      each(iconSet.icons, (icon: Icon) => {
        this.iconRegistry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(icon.path));
      });
    });
  }
}
