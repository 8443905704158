export enum NotificationModuleEnum {
  REGISTRATION = 1,
  CUSTOMER = 2,
  PRODUCT = 3,
  SPARE_PART = 4,
  PRICING = 5,
  QUOTATION = 6,
  CONTRACT = 7,
  ORDER = 8,
  PAYMENT = 9,
  ORDER_DELIVERY_FORM = 10
}
