import {RouteBranch} from '../route-branch';
import {RouteLeaf} from '../route-leaf';

export class ComponentSettingRoute extends RouteBranch {
  private _component: RouteLeaf;

  public get component(): RouteLeaf {
    return this._component;
  }

  public set component(value: RouteLeaf) {
    this.replace(this._component, value);
    this._component = value;
  }
}
