import {isNil} from 'lodash-es';
import moment from 'moment';

export class TimezoneUtils {
  public static convertToDateWithCurrentOffset(dateTimeString: string, timezoneCode: string): Date {
    let date: Date = null;

    if (!isNil(dateTimeString)) {
      const momentDate = moment(dateTimeString);
      const timezoneOffsetInMinutes = momentDate.clone().tz(timezoneCode).utcOffset();
      date = momentDate.add(timezoneOffsetInMinutes - momentDate.utcOffset(), 'minutes').toDate();
    }

    return date;
  }

  public static convertToISOStringWithoutCurrentOffset(date: Date, timezoneCode: string): string {
    const momentDate = moment(date);
    const timezoneOffsetInMinutes = momentDate.clone().tz(timezoneCode).utcOffset();

    return momentDate.subtract(timezoneOffsetInMinutes - momentDate.utcOffset(), 'minutes').toISOString();
  }

  public static convertToDateWithTimeZoneOffset(dateTimeString: Date): Date {
    return moment(dateTimeString).add(this.getTimeZoneOffsetInMinutes(), 'minutes').toDate();
  }

  public static convertTimeZoneOffset(dateTimeString: Date): any {
    return moment(dateTimeString).add(this.getTimeZoneOffsetInMinutes(), 'minutes');
  }

  public static getDate(dateTimeString: Date): string {
    const date = new Date(dateTimeString);
    const onlyDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return onlyDate;
  }

  public static setDate(date: Date): Date {
    date = new Date(date);
    let formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)).toISOString().split('T')[0];
    formattedDate = formattedDate + 'T00:00:00.000Z';
    return new Date(formattedDate);
  }

  private static getTimeZoneOffsetInMinutes(): number {
    return Math.abs(new Date().getTimezoneOffset());
  }
}
