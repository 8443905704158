import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvanceSearchService {
  private showAddButton$ = new BehaviorSubject<boolean>(false);
  private showDeleteButton$ = new BehaviorSubject<boolean>(false);
  private addButtonSubject = new Subject<void>();
  private deleteButtonSubject = new Subject<void>();

  public triggerAddButtonEvent() {
    this.addButtonSubject.next();
  }

  public getAddButtonEvent() {
    return this.addButtonSubject.asObservable();
  }

  public triggerDeleteButtonEvent() {
    this.deleteButtonSubject.next();
  }

  public getDeleteButtonEvent() {
    return this.deleteButtonSubject.asObservable();
  }

  public getShowAddButton() {
    return this.showAddButton$.asObservable();
  }

  public getShowDeleteButton() {
    return this.showDeleteButton$.asObservable();
  }

  set showAddButton(value: boolean) {
    this.showAddButton$.next(value);
  }

  set showDeleteButton(value: boolean) {
    this.showDeleteButton$.next(value);
  }
}
