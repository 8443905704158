import {IdName} from '@infrastructure/id-name';
import {isNil} from 'lodash-es';

export class SharedCustomerDataOptionResponse {
  public readonly id: number | null;
  public readonly companyName: string | null;

  public readonly status: IdName | null;
  public remark: string | null;

  constructor(data: any = null) {
    if (!isNil(data)) {
      Object.assign(this, data);
    }
  }

  public static fromJSON(data: any): SharedCustomerDataOptionResponse {
    const object = new SharedCustomerDataOptionResponse(data);
    return object;
  }
}
