<ng-container *ngIf="isUploadTitle">
  <app-vdw-title titleIcon="picture-frame" [title]="uploadTitle"></app-vdw-title>
</ng-container>
<div class="upload-sec" fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex *ngIf="isAddingOrEditing" (click)="fileUpload.click()" draggable="true" ngClass="{{ dragAreaClass }}" class="div-upload" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
    <mat-icon matSuffix svgIcon="upload"></mat-icon>
    <h4 class="label-upload">{{ 'GENERAL.ACTIONS.DRAG_AND_UPLOAD_IMAGE' | translate }}</h4>
    <input #fileUpload (change)="onFileChange($event)" type="file" class="file" multiple hidden />
  </div>
  <ng-container *ngIf="isIcon && isAddingOrEditing">
    <div
      [ngClass]="{'default-tooltip': isAllValid === tooltipCondition.DEFAULT, 'valid-tooltip': isAllValid === tooltipCondition.VALID, 'invalid-tooltip': isAllValid === tooltipCondition.INVALID}"
      fxFlex="10"
      fxLayoutAlign="center center"
    >
      <mat-icon [appTooltip]="tooltipContent" [svgIcon]="isSvgInfoIcon ? 'question-circle' : null" [ngClass]="{'question-circle': !isSvgInfoIcon}"></mat-icon>
    </div>
    <ng-template #tooltipContent>
      <div
        class="tooltip-file-format"
        [ngClass]="{
          'default-tooltip': isFormatValid === tooltipCondition.DEFAULT,
          'valid-tooltip': isFormatValid === tooltipCondition.VALID,
          'invalid-tooltip': isFormatValid === tooltipCondition.INVALID
        }"
      >
        <mat-icon [svgIcon]="isFormatValid === tooltipCondition.INVALID ? 'error-circle-red' : 'tick'"></mat-icon>
        <label>{{ fileFormatDescription | translate }}</label>
      </div>
      <div
        class="tooltip-file-size"
        [ngClass]="{'default-tooltip': isSizeValid === tooltipCondition.DEFAULT, 'valid-tooltip': isSizeValid === tooltipCondition.VALID, 'invalid-tooltip': isSizeValid === tooltipCondition.INVALID}"
      >
        <mat-icon [svgIcon]="isSizeValid === tooltipCondition.INVALID ? 'error-circle-red' : 'tick'"></mat-icon>
        <label>{{ 'SALES.CONTRACT.TOOLTIP_FILE_SIZE_DESCRIPTION' | translate: {size: overallFileSize + 'MB'} }}</label>
      </div>
    </ng-template>
  </ng-container>
</div>
<mat-label class="label-total"
  >{{ 'GENERAL.DISPLAY' | translate }}{{ title }} <span *ngIf="getTotalItem > 0">({{ getTotalItem }}) </span></mat-label
>
<div class="div-scroll div-gallery" *ngIf="getTotalItem > 0">
  <div class="div-gallery-container" fxLayout="row" fxLayoutGap="8px">
    <table class="full-width">
      <ng-container *ngFor="let file of files">
        <tr class="tr-hover" style="height: 40px">
          <td style="width: 60px" [class.disabled]="file.errorMessage || file.uploadDetail.isUploading">
            <ng-container *ngIf="!isIcon && !file.uploadDetail.isUploading">
              <img *ngIf="file.format !== 'pdf' || file.errorMessage" class="img" src="{{ file.review }}" />
              <pdf-viewer *ngIf="file.format === 'pdf' && !file.errorMessage" [src]="file.review" style="height: 40px; width: 40px" [show-all]="false" [original-size]="false"></pdf-viewer>
            </ng-container>
            <ng-container *ngIf="isIcon && !file.uploadDetail.isUploading">
              <img *ngIf="file.format === 'jpg'" class="img" src="{{ file.review }}" />
              <mat-icon *ngIf="file.format !== 'jpg'" matSuffix svgIcon="file-{{ file.format }}"></mat-icon>
            </ng-container>
          </td>
          <td [class.disabled]="file.errorMessage || file.uploadDetail.isUploading" class="td-overflow">
            <a (click)="isIcon && !isPreview ? downloadFile(file) : previewFile(file)">{{ file.displayName }}</a>
          </td>
          <td [class.disabled]="file.errorMessage || file.uploadDetail.isUploading">
            <mat-label class="label-file-size">{{ file.size }}</mat-label>
          </td>
          <td>
            <div fxLayout="row" fxLayoutAlign="end end">
              <mat-icon
                *ngIf="file.uploadDetail.isUploading && file.uploadDetail.progressbarValue === 100"
                class="icon-tick"
                matSuffix
                [svgIcon]="file.errorMessage ? 'error-circle-red' : 'tick'"
              ></mat-icon>
              <mat-icon
                [class.icon-disabled]="file.errorMessage || file.uploadDetail.isUploading"
                class="icon-file-format"
                *ngIf="!file.errorMessage && !file.uploadDetail.isUploading"
                matSuffix
                svgIcon="file-{{ file.format }}"
              ></mat-icon>
              <mat-icon
                [class.icon-disabled]="file.errorMessage || file.uploadDetail.isUploading"
                *ngIf="isAddingOrEditing && !file.uploadDetail.isUploading"
                class="icon-hidden-cross"
                matSuffix
                svgIcon="cross"
                (click)="deleteFile(file)"
              ></mat-icon>
            </div>
          </td>
        </tr>
        <tr class="disabled" *ngIf="file.uploadDetail.isUploading">
          <th></th>
          <th colspan="3">
            <mat-progress-bar mode="determinate" [value]="file.uploadDetail.progressbarValue"></mat-progress-bar>
          </th>
        </tr>
        <tr class="tr-error" *ngIf="!file.uploadDetail.isUploading">
          <th *ngIf="file.errorMessage" colspan="4">
            <div fxLayout="row" fxLayoutGap="4px">
              <mat-icon class="icon-error" matSuffix svgIcon="info-2"></mat-icon>
              <div class="p-error">
                {{ file.errorMessage }}
              </div>
            </div>
          </th>
        </tr>
      </ng-container>
    </table>
  </div>
</div>

<div fxLayout="column" style="margin-top: 8px" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="getTotalItem <= 0 && isShowDefault">
  <img style="height: 188px; margin-bottom: 8px" src="/assets/images/illustration/no-results-found.svg" />
  <mat-label style="color: #13296d; font-weight: 400">{{ 'OVERLAY.NO_DATA.TITLE_WITH_PARAM' | translate }}</mat-label>
</div>
