import {DecimalPipe} from '@angular/common';
import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';
import {DataFormatter} from '@application/helper/data-formatter';
import {TranslateService} from '@application/translation/translate.service';

@Directive({
  selector: '[resetToZero]'
})
export class ResetToZeroDirective {
  private readonly _dataFormatter: DataFormatter;

  constructor(private el: ElementRef, private control: NgControl, private readonly _decimalPipe: DecimalPipe, private readonly _translateService: TranslateService) {
    this._dataFormatter = new DataFormatter(this._translateService);
  }

  @HostListener('blur')
  onBlur() {
    const value = this.control.value;
    if (value === null || value === '') {
      this.control.control.setValue(this._dataFormatter.formatPriceCurrency(this._decimalPipe, '0'));
    }
  }
}
