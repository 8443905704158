import {RouteUtils} from '@application/routing/route-utils';
import {PageUrls} from '@domain/ag-grid/page-urls';
import {Translations} from '@domain/ag-grid/translations';
import {Module, PREVIEW_ONLY_MODULES} from '@domain/organization/role/module.enum';

export class ModuleUtils {
  public static getPageUrls(type: string): PageUrls {
    return {
      role: {
        overview: RouteUtils.paths.organization.role.absolutePath,
        add: RouteUtils.paths.organization.role.addRoute.absolutePath,
        edit: RouteUtils.paths.organization.role.editRoute.absolutePath,
        details: RouteUtils.paths.organization.role.detailsRoute.absolutePath
      },
      user: {
        overview: RouteUtils.paths.organization.user.absolutePath,
        add: RouteUtils.paths.organization.user.addRoute.absolutePath,
        edit: RouteUtils.paths.organization.user.editRoute.absolutePath,
        details: RouteUtils.paths.organization.user.detailsRoute.absolutePath
      },
      registration: {
        overview: RouteUtils.paths.customerRelationship.registration.absolutePath,
        add: RouteUtils.paths.customerRelationship.registration.addRoute.absolutePath,
        edit: RouteUtils.paths.customerRelationship.registration.editRoute.absolutePath,
        details: RouteUtils.paths.customerRelationship.registration.detailsRoute.absolutePath
      },
      customer: {
        overview: RouteUtils.paths.customerRelationship.customer.absolutePath,
        details: RouteUtils.paths.customerRelationship.customer.detailsRoute.absolutePath,
        add: RouteUtils.paths.customerRelationship.customer.addRoute.absolutePath
      },
      product: {
        overview: RouteUtils.paths.productCatalogue.product.absolutePath,
        add: RouteUtils.paths.productCatalogue.product.addRoute.absolutePath,
        edit: RouteUtils.paths.productCatalogue.product.editRoute.absolutePath,
        details: RouteUtils.paths.productCatalogue.product.detailsRoute.absolutePath
      },
      sparePart: {
        overview: RouteUtils.paths.productCatalogue.sparePart.absolutePath,
        add: RouteUtils.paths.productCatalogue.sparePart.addRoute.absolutePath,
        edit: RouteUtils.paths.productCatalogue.sparePart.editRoute.absolutePath,
        details: RouteUtils.paths.productCatalogue.sparePart.detailsRoute.absolutePath
      },
      pricing: {
        overview: RouteUtils.paths.productCatalogue.pricing.absolutePath,
        add: RouteUtils.paths.productCatalogue.pricing.addRoute.absolutePath,
        edit: RouteUtils.paths.productCatalogue.pricing.editRoute.absolutePath,
        details: RouteUtils.paths.productCatalogue.pricing.detailsRoute.absolutePath
      },
      productCategoryGroup: {
        overview: RouteUtils.paths.productCatalogue.productCategoryGroup.absolutePath
      },
      quotation: {
        overview: RouteUtils.paths.sales.quotation.absolutePath,
        add: RouteUtils.paths.sales.quotation.addRoute.absolutePath,
        edit: RouteUtils.paths.sales.quotation.editRoute.absolutePath,
        details: RouteUtils.paths.sales.quotation.detailsRoute.absolutePath
      },
      contract: {
        overview: RouteUtils.paths.sales.contract.absolutePath,
        add: RouteUtils.paths.sales.contract.addRoute.absolutePath,
        edit: RouteUtils.paths.sales.contract.editRoute.absolutePath,
        details: RouteUtils.paths.sales.contract.detailsRoute.absolutePath
      },
      order: {
        overview: RouteUtils.paths.sales.order.absolutePath,
        add: RouteUtils.paths.sales.order.addRoute.absolutePath,
        edit: RouteUtils.paths.sales.order.editRoute.absolutePath,
        details: RouteUtils.paths.sales.order.detailsRoute.absolutePath
      },
      payment: {
        overview: RouteUtils.paths.sales.payment.absolutePath,
        edit: RouteUtils.paths.sales.payment.editRoute.absolutePath,
        details: RouteUtils.paths.sales.payment.detailsRoute.absolutePath
      },
      paymentDetails: {
        add: RouteUtils.paths.sales.paymentDetail.addRoute.absolutePath,
        details: RouteUtils.paths.sales.paymentDetail.detailsRoute.absolutePath
      },
      broadcastMessage: {
        add: RouteUtils.paths.messageCentre.addRoute.absolutePath,
        details: RouteUtils.paths.messageCentre.detailsRoute.absolutePath
      },
      contactPerson: {
        details: RouteUtils.paths.customerRelationship.customerUserRelationship.detailsRoute.absolutePath
      },
      orderDelivery: {
        add: RouteUtils.paths.orderDelivery.addRoute.absolutePath
      },
      salesforce: {
        overview: RouteUtils.paths.salesforce.absolutePath
      },
      opportunityJacquard: {
        overview: RouteUtils.paths.opportunity.jacquard.absolutePath,
        details: RouteUtils.paths.opportunity.jacquard.detailsRoute.absolutePath,
        add: RouteUtils.paths.opportunity.jacquard.addRoute.absolutePath,
        edit: RouteUtils.paths.opportunity.jacquard.editRoute.absolutePath
      },
      componentSetting: {
        overview: RouteUtils.paths.opportunity.componentSetting.absolutePath
      },
      configurationGroup: {
        overview: RouteUtils.paths.opportunity.configurationSetting.absolutePath
      },
      machineType: {
        overview: RouteUtils.paths.opportunity.machineType.absolutePath
      },
      component: {
        overview: RouteUtils.paths.opportunity.componentSetting.component.absolutePath
      },
      configuration: {
        overview: RouteUtils.paths.opportunity.configurationSetting.absolutePath
      },
      customerPriceForm: {
        overview: RouteUtils.paths.customerRelationship.customerPriceForm.absolutePath,
        add: RouteUtils.paths.customerRelationship.customerPriceForm.addRoute.absolutePath,
        details: RouteUtils.paths.customerRelationship.customerPriceForm.detailsRoute.absolutePath
      },
      prospectCustomer: {
        overview: RouteUtils.paths.customerRelationship.prospectCustomer.absolutePath
      }
    }[type];
  }

  public static getTranslations(type: string): Translations {
    return {
      role: {
        entity: 'ORGANIZATION.ROLE.ROLE'
      },
      user: {
        entity: 'ORGANIZATION.USER.USER'
      },
      registration: {
        entity: 'CRM.REGISTRATION.CUSTOMER_FORM'
      },
      customer: {
        entity: 'CRM.CUSTOMER.MODULE_TITLE'
      },
      product: {
        entity: 'PRODUCT_CATALOGUE.PRODUCT.MODULE_TITLE'
      },
      sparePart: {
        entity: 'PRODUCT_CATALOGUE.SPARE_PART.MODULE_TITLE'
      },
      pricing: {
        entity: 'PRODUCT_CATALOGUE.PRICING.MODULE_TITLE'
      },
      productCategoryGroup: {
        entity: 'PRODUCT_CATALOGUE.PRODUCT_CATEGORY_GROUP.MODULE_TITLE'
      },
      quotation: {
        entity: 'SALES.QUOTATION.MODULE_TITLE'
      },
      contract: {
        entity: 'SALES.CONTRACT.MODULE_TITLE'
      },
      order: {
        entity: 'SALES.ORDER.MODULE_TITLE'
      },
      payment: {
        entity: 'SALES.PAYMENT.MODULE_TITLE'
      },
      orderDelivery: {
        entity: 'SALES.ORDER_DELIVERY.TITLE'
      },
      salesforce: {
        entity: 'SALESFORCE.SALESFORCE.MODULE_TITLE'
      },
      customerPriceForm: {
        entity: 'PRODUCT_CATALOGUE.CUSTOMER_PRICE_FORM.MODULE_TITLE'
      }
    }[type];
  }

  public static isPreviewOnly(module: Module): boolean {
    return PREVIEW_ONLY_MODULES.some((previewOnlyModule: Module) => previewOnlyModule === module);
  }
}
