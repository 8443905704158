import {Language} from '@application/translation/language/language.enum';
import {LocalizedData} from '@infrastructure/http/localied-data.interface';
import {L10nIntlService} from 'angular-l10n';
import {isEmpty, isEqual, isNil, isNumber} from 'lodash-es';

export class LocaleUtils {
  public static getDecimalSeparator(l10nIntlService: L10nIntlService): string {
    return this.formatNumber(1.1, l10nIntlService).charAt(1);
  }

  public static formatNumber(value: number, l10nIntlService: L10nIntlService, maximumFractionDigits = 13): string {
    const decimalPlacesLength = LocaleUtils.getDecimalCasesNumber(value);
    const nonDecimalValue = Math.trunc(value);
    const decimalValue = Math.abs(value - nonDecimalValue);
    const decimalFormat = l10nIntlService.formatNumber(decimalValue.toFixed(decimalPlacesLength), {digits: `1.0-${maximumFractionDigits}`});

    return !isNil(value) ? `${nonDecimalValue}${decimalFormat.substr(1)}` : null;
  }

  public static parseNumber(s: string | number, l10nIntlService: L10nIntlService): number {
    return isNumber(s) ? s : isEmpty(s) ? null : Number(s.replace(this.getDecimalSeparator(l10nIntlService), '.'));
  }

  private static getDecimalCasesNumber(value: number): number {
    let result = 0;
    if (!isEqual(Math.floor(value), value) && !isNil(value)) {
      result = value.toString().split('.')[1].length || 0;
    }
    return result;
  }

  public static getLanguageEnumKeyByValue(language: Language): string {
    return Object.keys(Language)[Object.values(Language).indexOf(language)];
  }

  public static getLocalizedObjects<T extends LocalizedData>(chineseData: any, englishData: any, createInstance: (data: any, language: any) => T): T[] {
    const localizedData: T[] = [];

    if (englishData !== null && englishData !== undefined) {
      localizedData.push(createInstance(englishData, this.getLanguageEnumKeyByValue(Language.EN).toString()));
    }

    if (chineseData !== null && chineseData !== undefined) {
      localizedData.push(createInstance(chineseData, this.getLanguageEnumKeyByValue(Language.ZH).toString()));
    }

    return localizedData;
  }

  public static getLocalizedObject(localizedObjects: any[], language: Language): any {
    return localizedObjects?.find((localizedObject: any) => localizedObject.language === this.getLanguageEnumKeyByValue(language));
  }

  public static toLanguageEnum = (value: string): Language | undefined => {
    return (Object.values(Language) as string[]).includes(value) ? (value as Language) : undefined;
  };
}
