import {Params, PRIMARY_OUTLET, Route, Router, UrlSegment, UrlSegmentGroup, UrlTree} from '@angular/router';
import {INavigationBarItem} from '@application/helper/navigation/navigation-bar-item';
import {NavigationBarRouteLinkItem} from '@application/helper/navigation/navigation-bar-route-link-item';
import {Module} from '@domain/organization/role/module.enum';
import {Permission} from '@domain/organization/role/permission.enum';
import {find, first, isEmpty, isNil, join, keys, map} from 'lodash-es';
import {AuthenticationRoute} from './authentication-route';
import {ComponentsRoute} from './components-route';
import {CustomerRelationshipRoute} from './customer-relationship/customer-relationship.routes';
import {CustomerRoute} from './customer-relationship/customer-route';
import {CustomerUserRelationshipRoute} from './customer-relationship/customer-user-relationship-route';
import {ProspectCustomerRoute} from './customer-relationship/prospect-customer.routes';
import {RegistrationRoute} from './customer-relationship/registration-route';
import {DashboardRoute} from './dashboard-route';
import {ErpCustomerRoute} from './erp/erp-customer-route';
import {ErpRoute} from './erp/erp-route';
import {BroadcastMessageRoute} from './message-centre/broadcast-message-route';
import {NotificationRoute} from './notification/notification.route';
import {OrderDeliveryFormRoute} from './order-delivery/order-delivery-form-route';
import {OrganizationRoute} from './organization/organization-route';
import {OrganizationalStructureRoute} from './organization/organizational-structure-route';
import {OrganizationalTreeChartRoute} from './organization/organizational-tree-chart-route';
import {RoleRoute} from './organization/role-route';
import {UserRoute} from './organization/user-route';
import {CustomerPriceFormRoute} from './product-catalogue/customer-price-form-route';
import {PricingRoute} from './product-catalogue/pricing-route';
import {ProductCatalogueRoute} from './product-catalogue/product-catalogue-route';
import {ProductCategoryGroupRoute} from './product-catalogue/product-category-group-route';
import {ProductRoute} from './product-catalogue/product-route';
import {SparePartRoute} from './product-catalogue/spare-part-route';
import {RootRoute} from './root-route';
import {RouteLeaf} from './route-leaf';
import {ComponentSettingRoute} from './sales-force/component-setting.routes';
import {ConfigurationSettingRoute} from './sales-force/configuration-setting.routes';
import {JacquardRoute} from './sales-force/jacquard-route';
import {MachineConfigurationRoute} from './sales-force/machine-configuration.routes';
import {MachineTypeRoute} from './sales-force/machine-type.routes';
import {OpportunityRoute} from './sales-force/opportunity-route';
import {OrderDeliveryRoute} from './sales-force/order-delivery.route';
import {ContractRoute} from './sales/contract-route';
import {OrderRoute} from './sales/order-route';
import {PaymentDetailRoute} from './sales/payment-detail-route';
import {PaymentRoute} from './sales/payment-route';
import {QuotationRoute} from './sales/quotation-route';
import {SalesRoute} from './sales/sales-route';

export class RouteUtils {
  public static paths: RootRoute = RouteUtils.initialize();
  public static navigationBarRouteLinkItems: INavigationBarItem[] = RouteUtils.initializeNavigationBarRouteLinkItems();

  public static addChildRoutesForModule(routes: Route[], path: string, childRoutes: Route[]): void {
    const route: Route = find(routes, ['path', path]);
    if (!isNil(route)) {
      route.children = childRoutes;
    }
  }

  public static buildPathFromSegmentsAndQueryParams(segments: UrlSegment[], params: Params): string {
    let path: string = join(segments, '/');
    if (!isEmpty(params)) {
      const queryStrings: string[] = map(keys(params), (key: string) => `${key}=${params[key]}`);
      path = `${path}?${join(queryStrings, '&')}`;
    }
    return path;
  }

  public static isKnownPath(pathSegments: string[]): boolean {
    return RouteUtils.paths.isKnownPath(pathSegments);
  }

  public static getDefaultRoute(): string {
    // const navigationBarRouteLinkItems: NavigationBarRouteLinkItem[] = NavigationBarItemUtils.filterNavigationBarItemsByPermissionAndMobileAvailability(
    //   this.navigationBarRouteLinkItems,
    //   subscription,
    //   isMobile
    // ) as NavigationBarRouteLinkItem[];

    const navigationBarRouteLinkItems: NavigationBarRouteLinkItem[] = this.navigationBarRouteLinkItems as NavigationBarRouteLinkItem[];
    return first(navigationBarRouteLinkItems).path;
  }

  public static getParentRoute(path: string, router: Router): string {
    const tree: UrlTree = router.parseUrl(path);
    const urlSegmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const segments: UrlSegment[] = urlSegmentGroup.segments;
    return first(segments).toString();
    //return find(router.config, (route: Route) => includes(route.path, first(segments).toString()));
  }

  private static initialize(): RootRoute {
    const rootRoute = new RootRoute('');

    rootRoute.unauthorized = new RouteLeaf('unauthorized');
    rootRoute.forbidden = new RouteLeaf('forbidden');
    rootRoute.notFound = new RouteLeaf('not-found');
    rootRoute.profile = new RouteLeaf('profile');

    rootRoute.authentication = new AuthenticationRoute('authentication');
    rootRoute.authentication.login = new RouteLeaf('login');
    rootRoute.authentication.logout = new RouteLeaf('logout');
    rootRoute.authentication.firstTimeLogin = new RouteLeaf('first-time-login');
    rootRoute.authentication.forgotPassword = new RouteLeaf('forgot-password');

    rootRoute.dashboard = new DashboardRoute('dashboard');
    rootRoute.dashboard.home = new DashboardRoute('home');

    rootRoute.organization = new OrganizationRoute('organization');

    rootRoute.organization.role = new RoleRoute('role', Module.ROLE, Permission.VIEW);
    rootRoute.organization.role.addRoute = new RouteLeaf('add', Module.ROLE, Permission.ADD);
    rootRoute.organization.role.editRoute = new RouteLeaf('edit/:id', Module.ROLE, Permission.EDIT);
    rootRoute.organization.role.detailsRoute = new RouteLeaf('details/:id', Module.ROLE, Permission.VIEW);

    rootRoute.organization.user = new UserRoute('user', Module.USER, Permission.VIEW);
    rootRoute.organization.user.addRoute = new RouteLeaf('add', Module.USER, Permission.ADD);
    rootRoute.organization.user.editRoute = new RouteLeaf('edit/:id', Module.USER, Permission.EDIT);
    rootRoute.organization.user.detailsRoute = new RouteLeaf('details/:id', Module.USER, Permission.VIEW);
    rootRoute.organization.user.phoneLogRoute = new RouteLeaf('phone-log', Module.USER, Permission.VIEW);

    rootRoute.organization.organizationalStructure = new OrganizationalStructureRoute('organizational-structure', Module.ORGANIZATIONAL_STRUCTURE, Permission.VIEW);

    rootRoute.organization.organizationTreeChart = new OrganizationalTreeChartRoute('organizational-tree-chart', Module.ORGANIZATIONAL_STRUCTURE, Permission.VIEW);

    rootRoute.customerRelationship = new CustomerRelationshipRoute('crm');

    rootRoute.customerRelationship.registration = new RegistrationRoute('registration', Module.REGISTRATION, Permission.VIEW);
    rootRoute.customerRelationship.registration.addRoute = new RouteLeaf('add', Module.REGISTRATION, Permission.ADD);
    rootRoute.customerRelationship.registration.editRoute = new RouteLeaf('edit/:id', Module.REGISTRATION, Permission.EDIT);
    rootRoute.customerRelationship.registration.detailsRoute = new RouteLeaf('details/:id', Module.REGISTRATION, Permission.VIEW);

    rootRoute.customerRelationship.customer = new CustomerRoute('customer', Module.CUSTOMER, Permission.VIEW);
    rootRoute.customerRelationship.customer.detailsRoute = new RouteLeaf(':id', Module.CUSTOMER, Permission.VIEW);
    rootRoute.customerRelationship.customer.addRoute = new RouteLeaf('add/:id', Module.CUSTOMER, Permission.ADD);

    rootRoute.customerRelationship.customerUserRelationship = new CustomerUserRelationshipRoute('customer-user-relationship', Module.CONTACT_PERSON, Permission.VIEW);
    rootRoute.customerRelationship.customerUserRelationship.editRoute = new RouteLeaf('edit/:id', Module.CONTACT_PERSON, Permission.EDIT);
    rootRoute.customerRelationship.customerUserRelationship.detailsRoute = new RouteLeaf('details/:id', Module.CONTACT_PERSON, Permission.VIEW);

    rootRoute.customerRelationship.customerPriceForm = new CustomerPriceFormRoute('customer-price-form', Module.CUSTOMER_PRICE_FORM, Permission.VIEW);
    rootRoute.customerRelationship.customerPriceForm.addRoute = new CustomerPriceFormRoute('add', Module.CUSTOMER_PRICE_FORM, Permission.ADD);
    rootRoute.customerRelationship.customerPriceForm.detailsRoute = new RouteLeaf('details/:id', Module.CUSTOMER_PRICE_FORM, Permission.VIEW);
    rootRoute.customerRelationship.customerPriceForm.editRoute = new RouteLeaf('edit/:id', Module.CUSTOMER_PRICE_FORM, Permission.EDIT);

    rootRoute.customerRelationship.prospectCustomer = new ProspectCustomerRoute('prospect-customer', Module.PROSPECT_CUSTOMER, Permission.VIEW);

    rootRoute.productCatalogue = new ProductCatalogueRoute('product-catalogue');

    rootRoute.productCatalogue.product = new ProductRoute('product', Module.PRODUCT, Permission.VIEW);
    rootRoute.productCatalogue.product.addRoute = new RouteLeaf('add', Module.PRODUCT, Permission.ADD);
    rootRoute.productCatalogue.product.editRoute = new RouteLeaf('edit/:id', Module.PRODUCT, Permission.EDIT);
    rootRoute.productCatalogue.product.detailsRoute = new RouteLeaf('details/:id', Module.PRODUCT, Permission.VIEW);

    rootRoute.productCatalogue.sparePart = new SparePartRoute('spare-part', Module.SPARE_PART, Permission.VIEW);
    rootRoute.productCatalogue.sparePart.addRoute = new RouteLeaf('add', Module.SPARE_PART, Permission.ADD);
    rootRoute.productCatalogue.sparePart.editRoute = new RouteLeaf('edit/:id', Module.SPARE_PART, Permission.EDIT);
    rootRoute.productCatalogue.sparePart.detailsRoute = new RouteLeaf('details/:id', Module.SPARE_PART, Permission.VIEW);

    rootRoute.productCatalogue.pricing = new PricingRoute('pricing', Module.PRICING, Permission.VIEW);
    rootRoute.productCatalogue.pricing.addRoute = new RouteLeaf('add', Module.PRICING, Permission.ADD);
    rootRoute.productCatalogue.pricing.editRoute = new RouteLeaf('edit/:id', Module.PRICING, Permission.EDIT);
    rootRoute.productCatalogue.pricing.detailsRoute = new RouteLeaf('details/:id', Module.PRICING, Permission.VIEW);

    rootRoute.productCatalogue.productCategoryGroup = new ProductCategoryGroupRoute('product-category-group');

    rootRoute.sales = new SalesRoute('sales');

    rootRoute.sales.quotation = new QuotationRoute('quotation', Module.QUOTATION, Permission.VIEW);
    rootRoute.sales.quotation.addRoute = new RouteLeaf('add', Module.QUOTATION, Permission.ADD);
    rootRoute.sales.quotation.editRoute = new RouteLeaf('edit/:id', Module.QUOTATION, Permission.EDIT);
    rootRoute.sales.quotation.detailsRoute = new RouteLeaf('details/:id', Module.QUOTATION, Permission.VIEW);

    rootRoute.sales.contract = new ContractRoute('contract', Module.CONTRACT, Permission.VIEW);
    rootRoute.sales.contract.addRoute = new RouteLeaf('add', Module.CONTRACT, Permission.ADD);
    rootRoute.sales.contract.editRoute = new RouteLeaf('edit/:id', Module.CONTRACT, Permission.EDIT);
    rootRoute.sales.contract.detailsRoute = new RouteLeaf('details/:id', Module.CONTRACT, Permission.VIEW);

    rootRoute.sales.order = new OrderRoute('order', Module.ORDER, Permission.VIEW);
    rootRoute.sales.order.addRoute = new RouteLeaf('add', Module.ORDER, Permission.ADD);
    rootRoute.sales.order.editRoute = new RouteLeaf('edit/:id', Module.ORDER, Permission.EDIT);
    rootRoute.sales.order.detailsRoute = new RouteLeaf('details/:id', Module.ORDER, Permission.VIEW);

    rootRoute.sales.payment = new PaymentRoute('payment', Module.PAYMENT, Permission.VIEW);
    rootRoute.sales.payment.editRoute = new RouteLeaf('edit/:id', Module.PAYMENT, Permission.EDIT);
    rootRoute.sales.payment.detailsRoute = new RouteLeaf('details/:id', Module.PAYMENT, Permission.VIEW);

    rootRoute.sales.paymentDetail = new PaymentDetailRoute('payment-details', Module.PAYMENT, Permission.EDIT);
    rootRoute.sales.paymentDetail.addRoute = new RouteLeaf('add', Module.PAYMENT, Permission.EDIT);
    rootRoute.sales.paymentDetail.detailsRoute = new RouteLeaf(':id', Module.PAYMENT, Permission.VIEW);

    rootRoute.customSetting = new RouteLeaf('custom-setting', Module.CUSTOM_SETTING, Permission.VIEW);

    rootRoute.messageCentre = new BroadcastMessageRoute('broadcast-message', Module.BROADCAST_MESSAGE, Permission.VIEW);
    rootRoute.messageCentre.addRoute = new RouteLeaf('add', Module.BROADCAST_MESSAGE, Permission.ADD);
    rootRoute.messageCentre.detailsRoute = new RouteLeaf('details/:id', Module.BROADCAST_MESSAGE, Permission.VIEW);

    rootRoute.notification = new NotificationRoute('notifications');

    rootRoute.orderDelivery = new OrderDeliveryFormRoute('order-delivery', Module.ORDER_DELIVERY, Permission.VIEW);
    rootRoute.orderDelivery.addRoute = new RouteLeaf('add', Module.ORDER_DELIVERY, Permission.ADD);
    rootRoute.orderDelivery.detailsRoute = new RouteLeaf('details/:id', Module.ORDER_DELIVERY, Permission.VIEW);
    rootRoute.orderDelivery.editRoute = new RouteLeaf('edit/:id', Module.ORDER_DELIVERY, Permission.EDIT);

    rootRoute.salesforce = new RouteLeaf('salesforce', Module.SALESFORCE, Permission.VIEW);

    rootRoute.opportunity = new OpportunityRoute('opportunity');

    rootRoute.opportunity.jacquard = new JacquardRoute('jacquard', Module.OPPORTUNITY_JACQUARD, Permission.VIEW);
    rootRoute.opportunity.jacquard.addRoute = new RouteLeaf('add', Module.OPPORTUNITY_JACQUARD, Permission.ADD);
    rootRoute.opportunity.jacquard.editRoute = new RouteLeaf('edit/:id', Module.OPPORTUNITY_JACQUARD, Permission.EDIT);
    rootRoute.opportunity.jacquard.detailsRoute = new RouteLeaf('detail/:id', Module.OPPORTUNITY_JACQUARD, Permission.VIEW);
    rootRoute.opportunity.jacquard.progressHistoryRoute = new RouteLeaf('progress-history/:id/machine/:machineId', Module.OPPORTUNITY_JACQUARD, Permission.VIEW);

    rootRoute.opportunity.orderDelivery = new OrderDeliveryRoute(':opportunityId/order-delivery', Module.OPPORTUNITY_JACQUARD, Permission.VIEW_ORDER_DELIVERY);
    rootRoute.opportunity.orderDelivery.addRoute = new RouteLeaf('add', Module.OPPORTUNITY_JACQUARD, Permission.ADD_ORDER_DELIVERY);
    rootRoute.opportunity.orderDelivery.editRoute = new RouteLeaf('edit/:id', Module.OPPORTUNITY_JACQUARD, Permission.EDIT_ORDER_DELIVERY);
    rootRoute.opportunity.orderDelivery.detailsRoute = new RouteLeaf('detail/:id', Module.OPPORTUNITY_JACQUARD, Permission.VIEW_ORDER_DELIVERY);

    rootRoute.opportunity.machineType = new MachineTypeRoute('machine-type', Module.COMPONENT, Permission.VIEW);

    rootRoute.opportunity.componentSetting = new ComponentSettingRoute('component-setting', Module.COMPONENT, Permission.VIEW);
    rootRoute.opportunity.componentSetting.component = new RouteLeaf('component', Module.COMPONENT, Permission.VIEW);

    rootRoute.opportunity.configurationSetting = new ConfigurationSettingRoute('configuration-setting', Module.CONFIGURATION_GROUP, Permission.VIEW);

    rootRoute.opportunity.machineConfiguration = new MachineConfigurationRoute('machine-configuration', Module.SALESFORCE, Permission.VIEW);
    rootRoute.opportunity.machineConfiguration.addRoute = new RouteLeaf('add', Module.SALESFORCE, Permission.VIEW);
    rootRoute.opportunity.machineConfiguration.editRoute = new RouteLeaf('edit/:id', Module.SALESFORCE, Permission.VIEW);
    rootRoute.opportunity.machineConfiguration.detailsRoute = new RouteLeaf('detail/:id', Module.SALESFORCE, Permission.VIEW);

    rootRoute.erp = new ErpRoute('erp', Module.ERP, Permission.VIEW);
    rootRoute.erp.erpCustomer = new ErpCustomerRoute('customer', Module.ERP, Permission.VIEW);
    rootRoute.erp.erpCustomer.editRoute = new RouteLeaf('edit/:id', Module.ERP, Permission.EDIT);
    rootRoute.erp.erpCustomer.detailsRoute = new RouteLeaf('details/:id', Module.ERP, Permission.VIEW);
    rootRoute.erp.erpCustomer.tabbedDetailsRoute = new RouteLeaf('tabbed-details/:customerId', Module.ERP, Permission.VIEW);

    const components = new ComponentsRoute('components');
    components.icons = new RouteLeaf('icons');
    components.colours = new RouteLeaf('colours');
    components.spacing = new RouteLeaf('spacing');
    components.typography = new RouteLeaf('typography');
    components.actions = new RouteLeaf('actions');
    components.forms = new RouteLeaf('forms');
    rootRoute.components = components;

    return rootRoute;
  }

  private static initializeNavigationBarRouteLinkItems(): INavigationBarItem[] {
    return [
      new NavigationBarRouteLinkItem({
        route: RouteUtils.paths.profile,
        id: 'profile',
        name: 'NAVIGATION.PROFILE.TITLE',
        icon: 'person-4',
        sort: 1,
        subItems: []
      }),
      new NavigationBarRouteLinkItem({
        route: null,
        id: 'organization',
        name: 'NAVIGATION.ORGANIZATION.TITLE',
        icon: 'organization',
        sort: 2,
        subItems: [
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.organization.role,
            id: 'role',
            name: 'NAVIGATION.ORGANIZATION.ROLE.TITLE',
            icon: null,
            sort: 1,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.organization.user,
            id: 'user',
            name: 'NAVIGATION.ORGANIZATION.USER.TITLE',
            icon: null,
            sort: 2,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.organization.organizationalStructure,
            id: 'organizational-structure',
            name: 'NAVIGATION.ORGANIZATION.ORGANIZATIONAL_STRUCTURE.TITLE',
            icon: null,
            sort: 3,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.organization.organizationTreeChart,
            id: 'organizational-tree-chart',
            name: 'NAVIGATION.ORGANIZATION.ORGANIZATIONAL_TREE_CHART.TITLE',
            icon: null,
            sort: 4,
            subItems: []
          })
        ]
      }),
      new NavigationBarRouteLinkItem({
        route: null,
        id: 'crm',
        name: 'NAVIGATION.CRM.TITLE',
        icon: 'customer',
        sort: 3,
        subItems: [
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.customerRelationship.registration,
            id: 'registration',
            name: 'NAVIGATION.CRM.REGISTRATION.TITLE',
            icon: null,
            sort: 1,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.customerRelationship.customer,
            id: 'customer',
            name: 'NAVIGATION.CRM.CUSTOMER.TITLE',
            icon: null,
            sort: 2,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.customerRelationship.customerUserRelationship,
            id: 'customerUserRelationship',
            name: 'NAVIGATION.CRM.CUSTOMER_USER_RELATIONSHIP.TITLE',
            icon: null,
            sort: 3,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.customerRelationship.customerPriceForm,
            id: 'customerUserRelationship',
            name: 'NAVIGATION.CRM.CUSTOMER_PRICE_FORM.TITLE',
            icon: null,
            sort: 4,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.customerRelationship.prospectCustomer,
            id: 'prospectCustomer',
            name: 'NAVIGATION.CRM.PROSPECT_CUSTOMER.TITLE',
            icon: null,
            sort: 5,
            subItems: []
          })
        ]
      }),
      new NavigationBarRouteLinkItem({
        route: null,
        id: 'product-catalogue',
        name: 'NAVIGATION.PRODUCT_CATALOGUE.TITLE',
        icon: 'product-catalogue',
        sort: 4,
        subItems: [
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.productCatalogue.product,
            id: 'product',
            name: 'NAVIGATION.PRODUCT_CATALOGUE.PRODUCT.TITLE',
            icon: null,
            sort: 1,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.productCatalogue.sparePart,
            id: 'spare-part',
            name: 'NAVIGATION.PRODUCT_CATALOGUE.SPARE_PART.TITLE',
            icon: null,
            sort: 2,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.productCatalogue.pricing,
            id: 'pricing',
            name: 'NAVIGATION.PRODUCT_CATALOGUE.PRICING.TITLE',
            icon: null,
            sort: 3,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.productCatalogue.productCategoryGroup,
            id: 'productCategoryGroup',
            name: 'NAVIGATION.PRODUCT_CATALOGUE.PRODUCT_CATEGORY_GROUP.TITLE',
            icon: null,
            sort: 4,
            subItems: []
          })
        ]
      }),
      new NavigationBarRouteLinkItem({
        route: null,
        id: 'sales',
        name: 'NAVIGATION.SALES.TITLE',
        icon: 'sales',
        sort: 5,
        subItems: [
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.sales.quotation,
            id: 'quotation',
            name: 'NAVIGATION.SALES.QUOTATION.TITLE',
            icon: null,
            sort: 1,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.sales.contract,
            id: 'contract',
            name: 'NAVIGATION.SALES.CONTRACT.TITLE',
            icon: null,
            sort: 2,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.sales.order,
            id: 'order',
            name: 'NAVIGATION.SALES.ORDER.TITLE',
            icon: null,
            sort: 3,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.sales.payment,
            id: 'payment',
            name: 'NAVIGATION.SALES.PAYMENT.TITLE',
            icon: null,
            sort: 3,
            subItems: []
          })
        ]
      }),
      new NavigationBarRouteLinkItem({
        route: RouteUtils.paths.orderDelivery,
        id: 'order-delivery',
        name: 'NAVIGATION.ORDER_DELIVERY.TITLE',
        icon: 'delivery-box',
        sort: 6,
        subItems: []
      }),
      new NavigationBarRouteLinkItem({
        route: RouteUtils.paths.customSetting,
        id: 'settings',
        name: 'NAVIGATION.SETTINGS.TITLE',
        icon: 'gears',
        sort: 7,
        subItems: []
      }),
      new NavigationBarRouteLinkItem({
        route: RouteUtils.paths.erp,
        id: 'ERP',
        name: 'NAVIGATION.ERP.TITLE',
        icon: 'erp',
        sort: 8,
        subItems: []
      }),
      new NavigationBarRouteLinkItem({
        route: null,
        id: 'salesforce',
        name: 'NAVIGATION.SALESFORCE.TITLE',
        icon: 'salesforce',
        sort: 9,
        subItems: [
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.opportunity.jacquard,
            id: 'jacquard',
            name: 'NAVIGATION.SALESFORCE.OPPORTUNITY_JACQUARD.TITLE',
            icon: null,
            sort: 1,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.opportunity.machineConfiguration,
            id: 'machine-configuration',
            name: 'NAVIGATION.SALESFORCE.MACHINE_CONFIGURATION.TITLE',
            icon: null,
            sort: 2,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.opportunity.componentSetting,
            id: 'component-setting',
            name: 'NAVIGATION.SALESFORCE.COMPONENT_SETTING.TITLE',
            icon: null,
            sort: 3,
            subItems: []
          }),
          new NavigationBarRouteLinkItem({
            route: RouteUtils.paths.opportunity.configurationSetting,
            id: 'configuration-group',
            name: 'NAVIGATION.SALESFORCE.CONFIGURATION_AND_GROUP_SETTING.TITLE',
            icon: null,
            sort: 4,
            subItems: []
          })
        ]
      }),
      new NavigationBarRouteLinkItem({
        route: RouteUtils.paths.salesforce,
        id: 'salesforceSync',
        name: 'NAVIGATION.SALESFORCE.SYNC',
        icon: 'salesforce',
        sort: 10,
        subItems: []
      })
    ];
  }
}
