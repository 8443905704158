import {Routes} from '@angular/router';
import {CanLoadViaAuthGuard} from '@application/helper/route-guard/can-load-via-auth-guard';
import {RouteUtils} from '@application/routing/route-utils';

export const commonLayoutRoutes: Routes = [
  {
    path: RouteUtils.paths.components.path,
    loadChildren: (): any => import('@presentation/pages/components/components.module').then((m: any) => m.ComponentsModule)
  },
  {
    path: RouteUtils.paths.profile.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/profile/profile.module').then((m: any) => m.ProfileModule)
  },
  {
    path: RouteUtils.paths.dashboard.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/dashboard/dashboard.module').then((m: any) => m.DashboardModule)
  },
  {
    path: RouteUtils.paths.organization.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/organization/organization.module').then((m: any) => m.OrganizationModule)
  },
  {
    path: RouteUtils.paths.customerRelationship.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/customer-relationship/customer-relationship.module').then((m: any) => m.CustomerRelationshipModule)
  },
  {
    path: RouteUtils.paths.productCatalogue.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/product-catalogue/product-catalogue.module').then((m: any) => m.ProductCatalogueModule)
  },
  {
    path: RouteUtils.paths.sales.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/sales/sales.module').then((m: any) => m.SalesModule)
  },
  {
    path: RouteUtils.paths.customSetting.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/custom-setting/custom-setting.module').then((m: any) => m.CustomSettingModule)
  },
  {
    path: RouteUtils.paths.messageCentre.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/message-centre/broadcast-message/broadcast-message.module').then((m: any) => m.BroadcastMessageModule)
  },
  {
    path: RouteUtils.paths.orderDelivery.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/order-delivery/order-delivery.module').then((m: any) => m.OrderDeliveryModule)
  },
  {
    path: RouteUtils.paths.notification.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/message-centre/notification/notification.module').then((m: any) => m.NotificationModule)
  },
  {
    path: RouteUtils.paths.salesforce.path,
    loadChildren: (): any => import('@presentation/pages/salesforce/salesforce.module').then((m: any) => m.SalesforceModule)
  },
  {
    path: RouteUtils.paths.erp.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/erp/erp.module').then((m: any) => m.ErpModule)
  },
  {
    path: RouteUtils.paths.opportunity.path,
    canLoad: [CanLoadViaAuthGuard],
    loadChildren: (): any => import('@presentation/pages/salesforce/opportunities/opportunity.module').then((m: any) => m.OpportunityModule)
  }
];
